import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Kontak from "./pages/Kontak";
import DetailAsrama from "./components/Pondok/DetailAsrama";
import ArtikelPage from "./pages/ArtikelPage";
import Pondok from "./pages/Pondok";
import NamaAsrama from "./components/Pondok/NamaAsrama";
import Sekolah from "./pages/Sekolah";
import Detailartikel from "./components/artikels/Detailartikel";
import Register2 from "./components/kontakkami/Register2";
import Information from "./pages/Information";
import Kurikulum from "./pages/Kurikulum";
import Detailprogram from "./components/Sekolah/Program/Detailprogram";
import ProgramPage from "./pages/ProgramPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <Home /> },

      {
        path: "information",
        element: <Information />,
      },
      {
        path: "kontak",
        element: <Kontak />,
      },

      {
        path: "artikel",
        element: <ArtikelPage />,
      },
      {
        path: "artikel/:slug",
        element: <Detailartikel />,
      },
      {
        path: ":slug",
        element: <Detailartikel />,
      },
      {
        path: "profil-sekolah",
        element: <Sekolah />,
      },
      {
        path: "profil-sekolah/program",
        element: <ProgramPage />,
      },
      {
        path: "profil-sekolah/program/:slug",
        element: <Detailprogram />,
      },
      {
        path: "program/:slug",
        element: <Detailprogram />,
      },
      {
        path: "profil-sekolah/program/detail-program",
        element: <Detailprogram />,
      },
      {
        path: "profil-sekolah/kurikulum",
        element: <Kurikulum />,
      },
      {
        path: "sejarah-pondok",
        element: <Pondok />,
      },
      {
        path: "sejarah-pondok/asrama",
        element: <NamaAsrama />,
      },
      {
        path: "sejarah-pondok/asrama/:Slug",
        element: <DetailAsrama />,
      },
      {
        path: "asrama/:Slug",
        element: <DetailAsrama />,
      },
      {
        path: "Detail-Artikel",
        element: <Detailartikel />,
      },
      {
        path: "register2",
        element: <Register2 />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
