import React from "react";
import { Link } from "react-router-dom";
import { useFetchData } from "../hooks/fetchData";
import { Artikel } from "../Interface/Artikel.interface";
import { handlerApi } from "../service/handlerApi";

const Artikellainnya = () => {
  const { data } = useFetchData<Artikel[]>(() =>
    handlerApi("/api/artikels?populate=Image&populate=Deskripsi_Artikel")
  );
  if (!data) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
        <p>Loading.....</p>
      </div>
    );
  }
  const maxArticlesToShow = 3;
  const articlesToShow = data?.slice(0, maxArticlesToShow);
  return (
    <div className="  px-[30px] ">
      <h3 className=" sm:text-[25px] text-[20px] mt-5 sm-mt-0 font-bold">
        Artikel Lainnya
      </h3>
      <div className=" bg-white p-4 shadow-md ">
        <div className="overflow-auto touch-auto">
          {articlesToShow.map((artikel, index) => (
            <React.Fragment key={index}>
              <Link to={`/artikel/${artikel.attributes.Slug}`}>
                <div className="w-[300px] sm:text-[24px] text-[18px] my-2 sm:my-5 sm:leading-[20px]">
                  <button className="shadow-md rounded-lg hover:bg-slate-200 p-4">
                    <div className="flex sm:block">
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL +
                          artikel.attributes.Image.data.attributes.url
                        }
                        alt="image"
                        className="sm:h-[200px] h-[100px] object-contain rounded-lg"
                      />
                      <div>
                        <p className="sm:text-[15px] text-left mt-4 font-bold ml-2 sm:ml-0 text-[12px]">
                          {artikel.attributes.Judul}
                        </p>
                        <div className="sm:mt-2 px-[10px]">
                          <div className="flex">
                            {artikel.attributes.Deskripsi_Artikel[0] && (
                              <div
                                key={0}
                                className="text-[10px] text-justify k sm:text-[12px]"
                              >
                                {artikel.attributes.Deskripsi_Artikel[0]
                                  .Paragraf.length > 50
                                  ? artikel.attributes.Deskripsi_Artikel[0].Paragraf.substring(
                                      0,
                                      50
                                    ) + "..."
                                  : artikel.attributes.Deskripsi_Artikel[0]
                                      .Paragraf}
                                <span className="text-blue-500">
                                  Lihat Selengkapnya
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </Link>
              <hr />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Artikellainnya;
