import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useFetchData } from "../hooks/fetchData";
import { handlerApi } from "../service/handlerApi";
import { Informasi } from "../Interface/Informasi.interface";

const Sliders = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider | null>(null);

  // Fetch slide data using your custom hook
  const {
    data: slidesData,
    loading,
    error,
  } = useFetchData<Informasi>(() =>
    handlerApi(
      "/api/informasi?populate[0]=Informasi&populate[1]=Slide.Image&populate[2]=Persyaratan_Umur&populate[3]=Persyaratan_Administrasi"
    )
  );

  // Handle slide changes when data is fetched
  useEffect(() => {
    if (slidesData && slidesData.attributes.Slide.length > 0) {
      // If data is available, set the slider's current slide to the first one
      setCurrentSlide(0);
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    }
  }, [slidesData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slideToPrevious = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      setCurrentSlide(currentSlide - 1);
    }
  };

  const slideToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <div className="slider-container">
      {loading && (
        <div className="bg-indigo-500 ...">
          <svg
            className="animate-spin h-5 w-5 mr-3 ..."
            viewBox="0 0 24 24"
          ></svg>
          Processing...
        </div>
      )}
      {error && <div>Error: {error}</div>}
      {slidesData && slidesData.attributes.Slide.length > 0 && (
        <Slider {...settings} ref={sliderRef}>
          {slidesData.attributes.Slide.map((slide, index) => (
            <div
              key={index}
              className="slide-item sm:mt-[110px] mt-[80px] sm:px-[20px] sm:h-[630px] h-[200px]"
            >
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  (slide.Image.data?.attributes?.url || "")
                }
                alt="slide"
              />
            </div>
          ))}
        </Slider>
      )}
      <div className="slider-controls">
        <button
          className="slider-button prev-button"
          onClick={slideToPrevious}
          disabled={currentSlide === 0}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="absolute left-3 sm:left-9 text-[25px] sm:text-[50px] mt-[-135px] sm:mt-[-450px] sm:hover:bg-white sm:p-3 sm:rounded-lg"
          />
        </button>
        <button
          className="slider-button next-button"
          onClick={slideToNext}
          disabled={
            currentSlide === (slidesData?.attributes.Slide.length || 0) - 1
          }
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className="absolute  right-3 sm:right-9 text-[25px] sm:text-[50px] mt-[-140px] sm:mt-[-450px] sm:hover:bg-white sm:p-3 sm:rounded-lg"
          />
        </button>
      </div>
    </div>
  );
};

export default Sliders;
