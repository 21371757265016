import { useFetchData } from "../hooks/fetchData";
import { handlerApi } from "../service/handlerApi";
import { Kontaks } from "../Interface/Kontak.interface";

const Contact = () => {
  const { data } = useFetchData<Kontaks>(() =>
    handlerApi(
      "/api/kontak?populate[0]=Dashboard_Kontak.Image&populate[1]=Kontak.Image&populate[2]=Sosials.Image"
    )
  );

  return (
    <div className="sm:pl-5 mb-3 font-andika">
      <h3 className="text-lg sm:text-[24px]">Kontak Kami</h3>
      <ul className="sm:mt-5 mt-2">
        {data?.attributes.Kontak.map((contact, index) => (
          <li key={index} className="pb-2 flex">
            <img
              src={
                process.env.REACT_APP_BASE_URL +
                contact.Image.data.attributes.url
              }
              alt="profil"
              className="object-contain "
            />
            <a
              href={contact.Url}
              className="hover:text-blue-500 pl-2 text-[20px] sm:text-[24px]"
            >
              {contact.Judul}
            </a>
          </li>
        ))}
      </ul>

      <div>
        <h3 className="text-lg sm:text-[24px] text-black">SOSIAL MEDIA</h3>
        <ul className="sm:pl-2 sm:mt-5 mt-2">
          {data?.attributes.Sosials.map((item, index) => (
            <li className="pb-2 flex" key={index}>
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  item.Image.data.attributes.url
                }
                alt="contact"
                className="w-[32px] object-contain  h-[32px] mt-1"
              />
              <a
                href={item.Url}
                className="hover:text-blue-500 pl-2 text-md sm:text-[24px]"
              >
                {item.Deskripsi}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Contact;
