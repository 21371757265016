import React, { useState, useRef, RefObject } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Slider, { Settings } from "react-slick"; // Import the Slider component and Settings type
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type SlidersProps = {
  slidesData: {
    id: Number;
    Image: string;
    Judul: string;
  }[];
};

const Sliders = ({ slidesData }: SlidersProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider | null>(null);

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slideToPrevious = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      setCurrentSlide(currentSlide - 1);
    }
  };

  const slideToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <div className="slider-container">
      {slidesData && slidesData.length > 0 && (
        <Slider {...settings} ref={sliderRef as RefObject<Slider>}>
          {slidesData.map((slide, index) => (
            <div
              key={index}
              className="slide-item items-center sm:h-[400px] h-[160px]"
            >
              <img
                src={process.env.REACT_APP_BASE_URL + slide.Image}
                alt="image"
              />
            </div>
          ))}
        </Slider>
      )}
      <div className="slider-controls">
        <button
          className="slider-button prev-button"
          onClick={slideToPrevious}
          disabled={currentSlide === 0}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="absolute left-7 sm:left-20 text-[25px] sm:text-[50px] mt-[-110px] sm:mt-[-250px] sm:hover:bg-white sm:p-3 sm:rounded-lg"
          />
        </button>
        <button
          className="slider-button next-button"
          onClick={slideToNext}
          disabled={currentSlide === (slidesData?.length || 0) - 1}
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className="absolute  right-7 sm:right-[470px] text-[25px] sm:text-[50px] mt-[-110px] sm:mt-[-250px] sm:hover:bg-white sm:p-3 sm:rounded-lg"
          />
        </button>
      </div>
    </div>
  );
};

export default Sliders;
