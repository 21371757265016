import Layanan from "../components/Pondok/Asrama";
import { useFetchData } from "../components/hooks/fetchData";
import { Pondok } from "../components/Interface/Pondok.interface";
import { handlerApi } from "../components/service/handlerApi";
import { Plane } from "../assets/img/icon";

const PondokPage = () => {
  const { data } = useFetchData<Pondok>(() =>
    handlerApi(
      "/api/sejarah-pondok?populate[0]=Dashboard_Pondok.Image&populate[1]=Dashboard_Pondok.Deskripsi&populate[2]=Misi_Pondok&populate[3]=Kategori_Asrama.Image&populate[4]=Kategori_Asrama.Deskripsi"
    )
  );

  return (
    <div
      className="w-full  
    bg-white  py-32 lg:py-50 font-andika  "
    >
      <div className="bg-[#A07A53] w-full p-2 px-[30px] sm:px-20 mt-[-70px] sm:mt-[-50px] ">
        <p className="text-[16px] text-white">Beranda &gt; Sejarah Pondok </p>
      </div>
      {/* Dashboard */}
      {data?.attributes.Dashboard_Pondok.map((item, index) => (
        <div
          key={index}
          className="sm:p-10 px-7 sm:pl-[70px] mt-5 sm:mt-0 sm:px-[40px] "
        >
          <h3 className="sm:text-[36px] text-[20px]  font-bold ">
            {item.Judul}
          </h3>
          <div className="flex flex-col-reverse sm:flex-row sm:mb-20  ">
            <div
              key={index}
              className="sm:text-[20px] text-[18px] text-justify sm:w-[634px] sm:mt-10 mt-5 indent-10 sm:justify-text-center"
            >
              {item.Deskripsi.map((deskripsi, index) => (
                <div key={index}> {deskripsi.Paragraf} </div>
              ))}
            </div>
            <img
              className="mt-2 object-contain rounded-md sm:w-[520.194px] sm:h-[475px] h-50 w-60"
              src={
                process.env.REACT_APP_BASE_URL + item.Image.data.attributes.url
              }
              alt="pondok"
            />
          </div>
        </div>
      ))}
      {/* Visi */}
      <div className="mt-5 sm:px-20 pl-6 pr-5">
        <div className="flex mb-5 pl-2">
          <img
            src={Plane}
            alt="Plane"
            className=" sm:w-[40.026px] sm:h-[40.026px] h-8 w-8"
          />
          <p className="sm:pl-5 pl-3 text-[20px] sm:text-[24px] font-bold">
            VISI
          </p>
        </div>
        <ul className="list-disc px-2 sm:w-100 text-[18px] sm:text-[20px] text-justify">
          {data?.attributes.Visi_Pondok}
        </ul>
      </div>
      {/* Misi */}
      <div className="mt-5 sm:pl-20 pl-8  sm:p-10">
        <div className="flex">
          <img
            src={Plane}
            alt="Plane"
            className="sm:w-[40.026px] sm:h-[40.026px] h-8 w-8"
          />
          <p className="pl-2 text-[20px] sm:text-[24px] font-bold">MISI</p>
        </div>
        <ul className="list-disc ml-8 leading-[30px] mt-2 sm:leading-[40px] sm:w-100 text-[18px] sm:text-[20px] text-justify pr-6">
          {data?.attributes.Misi_Pondok.map((statement, index) => (
            <li key={index}>{statement.Paragraf}</li>
          ))}
        </ul>
      </div>
      <div className="my-20">
        <Layanan />
      </div>
    </div>
  );
};

export default PondokPage;
