import { handlerApi } from "../service/handlerApi";
import { useFetchData } from "../hooks/fetchData";
import { KurikulumPageResponse } from "../Interface/KurikulumPage.interface";

const Info = () => {
  const { data } = useFetchData<KurikulumPageResponse[]>(() =>
    handlerApi("/api/kurikulums?populate=*")
  );

  if (!data) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
        <p>Loading.....</p>
      </div>
    );
  }
  return (
    <div className="w-full relative mt-[20px] bg-white font-andika  py-10">
      <p className="sm:text-[35px] text-[20px] font-bold text-center">
        Kurikulum
      </p>
      <div className="flex mx-auto  mt-[10px] justify-center items-center">
        {data?.map((item, index) => (
          <div
            key={index}
            className="bg-white w-[100px] sm:w-[300px] rounded-md py-[25px] sm:py-[30px] px-2 sm:px-[35px] mx-2 sm:mx-5 shadow-lg sm:shadow-xl flex flex-col items-center"
          >
            <img
              src={
                process.env.REACT_APP_BASE_URL +
                item.attributes.Image.data.attributes.url
              }
              alt="info"
              className="sm:w-[76px] sm:h-[76px]  h-[50px] object-contain"
            />

            <div className="text-center  sm:w-[210px]">
              <h3 className="text-[10px] sm:text-[20px] mt-2">
                {item.attributes.Judul}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Info;
