import { bg } from "../../assets/img/tarwil";
import { useFetchData } from "../hooks/fetchData";
import { handlerApi } from "../service/handlerApi";
import { Beranda } from "../Interface/Beranda.interface";

const Layanan = () => {
  const { data } = useFetchData<Beranda>(() =>
    handlerApi(
      "/api/beranda?populate[0]=artikels&populate[1]=artikels.Image&populate[2]=artikels.Deskripsi_Artikel&populate[3]=Layanan.Image&populate[4]=paqs"
    )
  );

  // Menambahkan penanganan jika data tidak tersedia
  if (!data) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
        <p>Loading.....</p>
      </div>
    );
  }
  return (
    <div className="w-full mx-auto  flex justify-center items-center overflow-hidden rounded-b-none relative flex-col">
      <div>
        <img src={bg} alt="bg" className="h-[230px] sm:h-[600px] w-full " />
      </div>
      <div className="flex absolute mt-[-160px] sm:mt-[-450px]  ">
        <h3 className="text-white text-xl sm:text-[48px] ">Layanan Kami</h3>
      </div>
      <div className="flex-row flex  absolute px-10">
        {data.attributes.Layanan.map((service, index) => (
          <div
            className="bg-white rounded-xl   sm:shadow-lg  items-center flex flex-col sm:pt-[32px] pt-[12px] sm:px-[20px] px-[1px] sm:pb-[50px] pb-[20px] sm:mx-8 mx-4"
            key={index}
          >
            <div>
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  service.Image.data.attributes.url
                }
                alt="service logo"
                className="sm:w-[176.812px]  h-[50px] object-contain sm:h-[100px] "
              />
            </div>
            <div className="text-center w-[130px]  sm:w-[230px] sm:text-[180px] ">
              <h3 className="text-[10px] sm:h-[80px] sm:text-[24px] mt-2">
                {service.Judul}
              </h3>
              <p className="sm:text-[12px] hidden sm:block text-justify text-[10px] ">
                {window.innerHeight >= 500
                  ? service.Deskripsi.slice(0, 0)
                  : service.Deskripsi.slice(0, 50)}
                {service.Deskripsi.length > 80}
                {service.Deskripsi}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Layanan;
