import React, { useEffect, useState } from "react";
import { Whatsapp, Symbol } from "../../assets/img/icon";
import AsramaLainnya from "./AsramaLainnya";
import { useParams } from "react-router-dom";
import { Asrama } from "../Interface/Asrama.inteface";
import { handlerApi } from "../service/handlerApi";
import Sliders from "./Sliders";

const DetailAsrama = () => {
  const { Slug } = useParams<{ Slug: string }>();
  const [data, setData] = useState<Asrama[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handlerApi(
          `/api/nama-asramas?populate[0]=Deskripsi&populate[1]=Slide.Image&populate[2]=urlPendaftaran&populate[3]=Image&filters[slug][$eq]=${Slug}`
        );
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [Slug]);
  console.log(data);
  // Filter the data to get the first matching article
  const filteredData = data.filter((asrama) => asrama.attributes.Slug === Slug);
  const firstAsrama = filteredData.length > 0 ? filteredData[0] : null;

  if (!firstAsrama)
    return (
      <div className="h-screen justify-center flex items-center">
        Article Not Found
      </div>
    );

  const { attributes } = firstAsrama;

  const SlidesData =
    attributes.Slide.map((item, index) => ({
      id: index,
      Judul: item.Judul,
      Image: item?.Image?.data?.attributes?.url,
    })) || [];

  return (
    <div className="sm:flex pt-[90px]">
      <div>
        <div className=" px-[20px] font-andika text-justify sm:pl-20 ">
          <div className="pb-5 ">
            <p className=" font-bold sm:text-[30px]">{attributes.Judul}</p>
          </div>
          <div className="sm:w-[800px] sm:h-[400px]">
            <Sliders slidesData={SlidesData} />
          </div>
          {attributes.Deskripsi.map((teks, index) => (
            <div className="py-5 text-[18px]   sm:text-[20px] sm:mr-10">
              <p className="bg-white">{teks.Paragraf}</p>
            </div>
          ))}
          <div className="sm:flex text-[18px] sm:text-[20px] mt-5">
            <div className="bg-[#3BB3C3] hover:bg-slate-500 px-5 items-center mr-2 sm:mr-10 rounded-3xl">
              <a
                href={attributes.urlWhatsApp}
                target="_blank"
                rel="noopener noreferrer"
                className="flex"
              >
                <img src={Whatsapp} alt="Whatsapp" className="h-10 p-2" />
                <h3 className="py-2  text-white">Konsultasi Sekarang</h3>
              </a>
            </div>
            <div className="border-2 hover:bg-slate-500 mt-3 sm:mt-0 px-5 items-center  rounded-3xl">
              <a
                href={`${process.env.REACT_APP_BASE_URL}${attributes.urlPendaftaran.data.attributes.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex"
              >
                <img src={Symbol} alt="Symbol" className="h-10 p-2" />
                <h3 className="py-2 hover:text-white">Panduan Pendaftaran</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <AsramaLainnya />
      </div>
    </div>
  );
};

export default DetailAsrama;
