import { Plane } from "../assets/img/icon";
import { handlerApi } from "../components/service/handlerApi";
import { useFetchData } from "../components/hooks/fetchData";
import { Informasi } from "../components/Interface/Informasi.interface";
import Hero from "../components/beranda/Hero";

const Misi = () => {
  const { data } = useFetchData<Informasi>(() =>
    handlerApi(
      "/api/informasi?populate[0]=Informasi&populate[1]=Slide.Image&populate[2]=Persyaratan_Umur&populate[3]=Persyaratan_Administrasi"
    )
  );

  // Menambahkan penanganan jika data tidak tersedia
  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <div className="w-full   font-andika bg-white">
      <Hero />
      <div className="text-center mx-[60px] sm:mx-[400px]">
        <p className=" sm:text-[24px] text-[14px] rounded-lg shadow-md font-bold">
          PERSYARATAN MENDAFTAR DI MIS NIHAYATUL AMAL RAWAMERTA
        </p>
      </div>
      <div className="sm:flex-col  ">
        <div className=" sm:ml-20 mx-5 my-[50px] shadow-lg sm:w-[800px] sm:pl-8 p-3  ">
          <div className="flex ">
            <img
              src={Plane}
              alt="Plane"
              className="sm:w-[40.026px] sm:h-[40.026px] h-8 w-8"
            />
            <p className="pl-2 px-5 text-[12px] rounded-lg shadow-md sm:text-[24px] font-bold">
              PERSYARATAN UMUR
            </p>
          </div>
          <ul className="list-disc ml-8 leading-[20px] mt-2 sm:leading-[40px] sm:w-100 text-[12px] sm:text-[20px] text-justify sm:pr-6">
            {data.attributes.Persyaratan_Umur.map((statement, index) => (
              <li key={index}>{statement.Paragraf}</li>
            ))}
          </ul>
        </div>
        <div className=" sm:ml-[400px] sm:w-[900px] mt-10 mr-6 shadow-lg ml-8  sm:p-10">
          <div className="flex">
            <img
              src={Plane}
              alt="Plane"
              className="sm:w-[40.026px] sm:h-[40.026px] h-8 w-8"
            />
            <p className="pl-2  p-2 rounded-md text-[14px] sm:text-[24px] font-bold">
              PERSYARATAN ADMINISTRASI
            </p>
          </div>
          <ul className="list-disc  ml-8 leading-[20px] pb-4 sm:leading-[40px] sm:w-100 text-[12px] sm:text-[20px] text-justify pr-6">
            {data?.attributes.Persyaratan_Administrasi.map(
              (statement, index) => (
                <li key={index}>{statement.Paragraf}</li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Misi;
