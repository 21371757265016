import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Img } from "../../assets/img/tarwil";
import { handlerApi } from "../service/handlerApi";
import { useFetchData } from "../hooks/fetchData";
import { Asrama } from "../Interface/Asrama.inteface";

const Asramalainnya = () => {
  const { data } = useFetchData<Asrama[]>(() =>
    handlerApi("/api/nama-asramas?populate=*")
  );

  // Create a state variable to store the shuffled data
  const [shuffledData, setShuffledData] = useState<Asrama[] | null>(null);

  useEffect(() => {
    // Shuffle the data when it is initially loaded
    if (data) {
      const shuffled = [...data];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      setShuffledData(shuffled);
    }
  }, [data]);

  const maxArticlesToShow = 3;
  const articlesToShow = shuffledData?.slice(0, maxArticlesToShow);

  return (
    <div className="sm:mr-10 mt-[120px] px-[30px]">
      <h3 className=" sm:text-[30px] text-[28px] mt-5 sm-mt-0 font-bold">
        Asrama Lainnya
      </h3>

      {articlesToShow?.map((asrama, index) => (
        <React.Fragment key={index}>
          <Link to={`/asrama/${asrama.attributes.Slug}`}>
            <p className="w-[300px] sm:text-[24px] text-[18px] my-2 sm:my-5 sm:leading-[20px]">
              <button className="shadow-md rounded-lg hover:bg-slate-200 p-4">
                <div className="flex sm:block">
                  {/* Optional chaining to handle potential null or undefined */}
                  <img
                    src={
                      process.env.REACT_APP_BASE_URL +
                      (asrama.attributes.Image?.data?.attributes?.url || "")
                    }
                    alt="image"
                    className="sm:h-[200px] h-[100px] object-contain rounded-lg"
                  />
                  <div>
                    <p className="sm:text-[15px] font-bold sm:text-center text-left mt-4 ml-2 sm:ml-0 text-[12px]">
                      {/* Optional chaining to handle potential null or undefined */}
                      {asrama.attributes?.Judul}
                    </p>
                  </div>
                </div>
              </button>
            </p>
          </Link>
          <hr />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Asramalainnya;
