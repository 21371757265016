import Kurikulumdata from "../components/Sekolah/Kurikulumdata";
import { handlerApi } from "../components/service/handlerApi";
import { useFetchData } from "../components/hooks/fetchData";
import { ProfilSekolah } from "../components/Interface/ProfilSekolah.interface";

const Kurikulum = () => {
  const { data } = useFetchData<ProfilSekolah>(() =>
    handlerApi(
      "/api/profil-sekolah?populate[0]=Dashboard_Sekolah.Deskripsi&populate[1]=programs.Slide.Image&populate[2]=Dashboard_Sekolah.Image&populate[3]=Misi_Sekolah&populate[4]=PageKurikulum.Deskripsi&populate[5]=PageKurikulum.kurikulums.Deskripsi&populate[6]=PageKurikulum.kurikulums.Image"
    )
  );

  // Menambahkan penanganan jika data tidak tersedia
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full bg-white font-andika pt-32 lg:py-50">
      <div className="bg-[#8C6946] w-full p-2 mt-[-65px] px-[25px] sm:px-20 sm:mt-[-45px] ">
        <p className="sm:text-[16px] text-[12px] text-white">
          Profil Sekolah &gt; Kurikulum
        </p>
      </div>
      <div>
        <div className="sm:pl-[70px] pl-5 sm:mt-5 mt-3  mb-2 sm:mb-5">
          <p className=" text-[25px] text-center sm:text-[36px] px-2">
            Kurikulum
          </p>
        </div>
        <div className="sm:px-[40px] sm:w-[1347px]  ">
          <div className=" indent-8  mx-9 mb-5 font-w-400 sm:leading-[40px] leading-[24px] sm:w-100 text-[17px] text-justify  sm:text-[24px] ">
            {data?.attributes.PageKurikulum.Deskripsi.map(
              (statement, index) => (
                <p key={index} className="mb-5">
                  {statement.Paragraf}
                </p>
              )
            )}
          </div>
          <div className="sm:px-5 px-2">
            <Kurikulumdata />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kurikulum;
