import { Link } from "react-router-dom";
import { handlerApi } from "../service/handlerApi";
import { useFetchData } from "../hooks/fetchData";
import { KurikulumPageResponse } from "../Interface/KurikulumPage.interface";

const Kurikulum = () => {
  const { data } = useFetchData<KurikulumPageResponse[]>(() =>
    handlerApi("/api/kurikulums?populate=*")
  );
  if (!data) {
    return <div>Loading...</div>;
  }

  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="mt-20 font-andika">
      <p className="sm:text-[48px] text-[20px] font-bold text-center">
        Kurikulum
      </p>
      <div className="text-right sm:mr-[100px] pr-[30px] text-[15px] sm:text-[20px] mt-5 ">
        <Link
          to={"kurikulum"}
          className="text-[#3BB3C3]"
          onClick={handleNavClick}
        >
          Lihat Kurikulum
        </Link>
        <span className="pl-2">
          <i className="fa-solid fa-chevron-up fa-rotate-90 p-1 bg-[#3BB3C3] hover:bg-slate-500 text-white rounded-full " />
        </span>
      </div>
      <div className="max-w-7xl sm:pl-[180px]  sm:mx-auto  p-5 justify-center items-center sm:m-8 grid grid-cols-3 sm:grid-cols-4 sm:gap-4 gap-2">
        {data?.map((item, index) => (
          <div
            key={index}
            className="bg-white h-full rounded-md sm:p-6 p-2 shadow-lg flex flex-col items-center mb-5 sm:mx-5"
          >
            <div>
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  item.attributes.Image.data.attributes.url
                }
                alt="icon1"
                className="sm:h-[76px] sm:w-[76px] h-[50px] w-[50px] object-contain"
              />
            </div>
            <div className="aspect-w-1 aspect-h-1">
              <div className="flex flex-col items-center text-center">
                <h3 className=" w-auto h-auto text-[10px] sm:text-[16px] mt-2">
                  {item.attributes.Judul}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center">
        <h3 className="sm:text-[48px] text-[20px] font-bold">Program Kami</h3>
      </div>
    </div>
  );
};

export default Kurikulum;
