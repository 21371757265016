import React from "react";
import { Plane } from "../../assets/img/icon";
import { handlerApi } from "../service/handlerApi";
import { useFetchData } from "../hooks/fetchData";
import { ProfilSekolah } from "../Interface/ProfilSekolah.interface";

const Kurikulumdata = () => {
  const { data } = useFetchData<ProfilSekolah>(() =>
    handlerApi(
      "/api/profil-sekolah?populate[0]=Dashboard_Sekolah.Deskripsi&populate[1]=programs.Slide.Image&populate[2]=Dashboard_Sekolah.Image&populate[3]=Misi_Sekolah&populate[4]=PageKurikulum.Deskripsi&populate[5]=PageKurikulum.kurikulums.Deskripsi&populate[6]=PageKurikulum.kurikulums.Image&populate[7]=programs.Deskripsi"
    )
  );

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        {data?.attributes.PageKurikulum.kurikulums.data.map((item, index) => (
          <div className="mb-7" key={index}>
            <div className="flex px-5">
              <img
                src={Plane}
                alt="plane"
                className="sm:w-[40.026px] sm:h-[40.026px] w-[30px] h-[30px]"
              />
              <h3 className="sm:pl-3 pl-2 sm:text-[24px] text-[18px] font-bold">
                {item.attributes.Judul}
              </h3>
            </div>
            <div className="text-justify px-7 sm:px-5 mt-2">
              {item.attributes.Deskripsi.map((paragraph, Index) => (
                <p
                  key={Index}
                  className="text-[16px] sm:pl-[50px] sm:text-[24px] sm:leading-[40px] leading-[20px]"
                >
                  {Index + 1}. {paragraph.Paragraf}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Kurikulumdata;
