import Register2 from "../components/kontakkami/Register2";
import { useFetchData } from "../components/hooks/fetchData";
import { Kontaks } from "../components/Interface/Kontak.interface";
import { handlerApi } from "../components/service/handlerApi";
import Contact from "../components/kontakkami/Contact";
import { Logomina } from "../assets/img/tarwil";

const Kontak = () => {
  const { data } = useFetchData<Kontaks>(() =>
    handlerApi(
      "/api/kontak?populate[0]=Dashboard_Kontak.Image&populate[1]=Kontak.Image&populate[2]=Sosials.Image"
    )
  );

  return (
    <div className="w-full bg-white font-andika  pt-[80px] sm:pt-[100px] flex flex-col">
      <div className="bg-[#8C6946] w-full p-3  mt-[-24px]">
        <p className="text-[ 16px] pl-5 sm:pl-[70px] text-white">
          Beranda &gt; Kontak
        </p>
      </div>

      {/* Dashboard */}

      <div>
        <div>
          <p className="sm:pt-8 pt-4 pl-10  sm:pl-20 text-[24px] mb-3 sm:text-[36px] text-[#EF841A]">
            {data?.attributes.Dashboard_Kontak.Judul}
          </p>
        </div>
        <div className="sm:flex font-andika pl-10">
          <img
            src={Logomina}
            alt="kontak"
            className="sm:p-10 sm:w-[443.797px] sm:h-[251px] w-[250px] h-[200px] object-contain"
          />

          <div className="sm:px-5 pr-5 sm:pr-0 text-[18px] sm:text-[24px] sm:w-[750px] mt-5 sm:mt-20">
            <p>{data?.attributes.Dashboard_Kontak.Deskripsi}</p>
          </div>
        </div>
      </div>

      {/* Kontak Kami */}

      <div className="flex flex-col-reverse  mt-5 sm:flex-row px-10 sm:px-20  my-5 sm:my-10 ">
        <div className=" sm:w-[718px]  h-[300px] sm:h-[500px]  ">
          <a href={data?.attributes.Dashboard_Kontak.Maps}>
            <iframe
              title="Google Map"
              src={data?.attributes.Dashboard_Kontak.Maps}
              width="100%"
              height={450}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </a>
        </div>
        <div className="flex flex-col   mb-3">
          <div className="w-170 sm:w-[317px]  sm:h-[80px]   gap-8 sm:px-5 mt-2 my-1 sm:mx-1">
            <Contact />
          </div>
        </div>
      </div>

      <Register2 />
    </div>
  );
};

export default Kontak;
