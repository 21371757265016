import { Link } from "react-router-dom";
import { handlerApi } from "../service/handlerApi";
import { useFetchData } from "../hooks/fetchData";
import { Pondok } from "../Interface/Pondok.interface";
import { useState } from "react";

const Asrama = () => {
  const [openIndex, setOpenIndex] = useState(-1);
  const { data } = useFetchData<Pondok>(() =>
    handlerApi(
      "/api/sejarah-pondok?populate[0]=Dashboard_Pondok.Image&populate[1]=Dashboard_Pondok.Deskripsi&populate[2]=Misi_Pondok&populate[3]=Kategori_Asrama.Image&populate[4]=Kategori_Asrama.Deskripsi"
    )
  );

  if (!data) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
        <p>Loading.....</p>
      </div>
    );
  }

  const toggle = (index: number) => {
    if (openIndex === index) {
      // Jika yang diklik adalah yang sedang terbuka, tutup.
      setOpenIndex(-1);
    } else {
      // Jika yang diklik adalah yang tertutup, buka.
      setOpenIndex(index);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="sm:text-[48px] mb-10 text-[20px]">
        <p className="font-bold  text-center ">PROGRAM </p>
        <Link
          to={"asrama"}
          className="text-[13px] sm:text-[24px] hover:text-blue-400 absolute right-10 "
          onClick={scrollToTop}
        >
          Lihat Asrama...
          <i className="fa-solid fa-chevron-up fa-rotate-90 p-1 bg-gray-500 text-[10px] sm:text-[20px] rounded-full" />
        </Link>
      </div>
      <div className="w-full sm:mx-[200px] px-8 justify-center  grid grid-cols-3 sm:grid-cols-4 gap-4">
        {data?.attributes?.Kategori_Asrama.map((asrama, index) => (
          <div key={index}>
            <div className="bg-white h-full rounded-md sm:p-6 shadow-lg flex flex-col items-center sm:mb-5  cursor-pointer">
              <div>
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    asrama?.Image.data.attributes?.url
                  }
                  alt="Image"
                  className="sm:h-[80px] sm:w-[80px] h-[40px] w-[40px]"
                />
              </div>
              <div className="text-center">
                <h3 className="sm:text-[20px] text-[13px] mt-2">
                  {asrama?.Judul}
                </h3>
                <div
                  onClick={() => toggle(index)}
                  className="sm:mt-5 mt-2 mb-3 text-white"
                >
                  <i className="fa-solid fa-chevron-up fa-rotate-90 p-2 bg-gray-500 hover:bg-gray-800 text-[10px] sm:text-[20px] rounded-full" />
                </div>
                <div>
                  {openIndex === index && (
                    <div className="absolute bg-white indent-9 shadow-xl rounded-md sm:right-[135px] right-[30px] sm:left-[200px] left-[30px] text-justify sm:p-6 p-3 mt-[5px] sm:mt-[60px]">
                      {asrama.Deskripsi.map((deskrpsi, index) => (
                        <div key={index} className="text-[14px] sm:text-[18px]">
                          {" "}
                          {deskrpsi.Paragraf}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Asrama;
