import { useEffect, useState } from "react";
import Artikellainnya from "./Artikellainnya";
import { Artikel } from "../Interface/Artikel.interface";
import { handlerApi } from "../service/handlerApi";
import { useParams } from "react-router-dom";

const Detailartikel = () => {
  const { slug } = useParams<{ slug: string }>();
  const [data, setData] = useState<Artikel[]>([]); // Initialize as an empty array

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handlerApi(
          `/api/artikels?populate=Image&populate=Deskripsi_Artikel&filters[slug][$eq]=${slug}`
        );
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [slug]);

  // Filter the data to get the first matching article
  const filteredData = data.filter(
    (article) => article.attributes.Slug === slug
  );
  const firstArticle = filteredData.length > 0 ? filteredData[0] : null;

  if (!firstArticle)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
        <p>Loading.....</p>
      </div>
    );

  const { attributes } = firstArticle;

  return (
    <div className="w-full bg-white font-andika pt-32 lg:py-50">
      <div className="bg-[#A07A53] fixed w-full  mt-[-65px] sm:mt-[-50px] ">
        <p className="sm:text-[16px] text-[12px] text-white  px-8 sm:px-20 py-2">
          Beranda &gt; Artikel &gt; Detail{" "}
        </p>
      </div>
      <div className="sm:flex">
        <div>
          <div className="px-10 sm:pl-14 sm:mt-10 sm:flex">
            <img
              src={
                process.env.REACT_APP_BASE_URL +
                attributes.Image.data.attributes.url
              }
              alt="artikel"
              className="sm:w-[700px] sm:h-[500px] w-[400px] rounded-xl object-contain"
            />
          </div>
          <div className="px-10 sm:px-20 mt-10  justify-text-center ">
            <h3 className="sm:text-[36px] sm:w-[850px] text-[18px] font-bold sm:font-w-400  sm:leading-[36px]">
              {attributes.Judul}
            </h3>
            <p className="text-[#4F4F4F] sm:my-3 text-[10px] sm:text-[24px] font-w-400  sm:leading-[36px] leading-[20px]">
              {attributes.UpdateArtikel}
            </p>
            {attributes.Deskripsi_Artikel.map((data, index) => (
              <p
                key={index}
                className="indent-[20px] sm:w-[850px]  text-[14px] text-justify sm:text-[20px]  mb-4 font-w-400  sm:leading-[36px]"
              >
                {data.Paragraf}
              </p>
            ))}
          </div>
        </div>
        <div>
          <Artikellainnya />
        </div>
      </div>
    </div>
  );
};

export default Detailartikel;
