import React from "react";
import { useFetchData } from "../hooks/fetchData";
import { Kontaks } from "../Interface/Kontak.interface";
import { handlerApi } from "../service/handlerApi";

const Kontak: React.FC = () => {
  const { data } = useFetchData<Kontaks>(() =>
    handlerApi(
      "/api/kontak?populate[0]=Dashboard_Kontak.Image&populate[1]=Kontak.Image&populate[2]=Sosials.Image"
    )
  );
  return (
    <div className="text-[16px] font-andika leading-normal font-w-400 not-italic">
      <h4>KONTAK KAMI</h4>
      <ul className="mt-3 leading-[40px]">
        {data?.attributes.Kontak.map((contact, index) => (
          <li className="flex" key={index}>
            {contact.Judul === "phone" ? (
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  contact.Image.data.attributes.url
                }
                alt="logo5"
                className="h-[24px] w-[24px] mt-2"
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  contact.Image.data.attributes.url
                }
                alt="logo2"
                className="h-[24px] w-[24px] mt-2"
              />
            )}
            {contact.Judul === "phone" ? (
              <a href={contact.Url} className="pl-2 hover:text-blue-500">
                {contact.Deskripsi}
              </a>
            ) : (
              <a
                href={contact.Url}
                className="pl-2 text-[16px] hover:text-blue-500"
              >
                {contact.Judul}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Kontak;
