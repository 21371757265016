import React from "react";
import { Link } from "react-router-dom";
import { ProfilSekolah } from "../../Interface/ProfilSekolah.interface";
import { useFetchData } from "../../hooks/fetchData";
import { handlerApi } from "../../service/handlerApi";

const ProgramCard = () => {
  const { data } = useFetchData<ProfilSekolah>(() =>
    handlerApi(
      "/api/profil-sekolah?populate[0]=Dashboard_Sekolah.Deskripsi&populate[1]=programs.Slide.Image&populate[2]=Dashboard_Sekolah.Image&populate[3]=Misi_Sekolah&populate[4]=PageKurikulum.Deskripsi&populate[5]=PageKurikulum.kurikulums.Deskripsi&populate[6]=PageKurikulum.kurikulums.Image&populate[7]=programs.Deskripsi"
    )
  );

  // Menambahkan penanganan jika data tidak tersedia
  if (!data) {
    return <div>Loading...</div>;
  }
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="w-full bg-white py-2 font-andika lg:py-25">
      <div className=" sm:ml-[1050px] ">
        <Link
          to={"Program"}
          className=" sm:text-[20px] text-[16px] sm:absolute sm:text-right-10 sm:mt-[-20px] ml-[230px] sm:ml-0 text-blue-400  hover:text-blue-500"
          onClick={scrollToTop}
        >
          Lihat Berikutnya
          <span className="pl-2">
            <i className="fa-solid fa-chevron-up fa-rotate-90 p-1 bg-[#3BB3C3] hover:bg-slate-500 text-white rounded-full " />
          </span>
        </Link>
      </div>
      <div>
        <div className="my-5 px-10 sm:px-20 grid grid-cols-1 sm:grid-cols-2 gap-5">
          {data?.attributes.programs.data.map((program, index) => (
            <div key={index}>
              <Link
                to={`program/${program.attributes.slug}`}
                onClick={scrollToTop}
              >
                <div className="bg-white hover:bg-slate-200  shadow-md flex flex-col sm:flex-row sm:w-7xl  p-5 rounded-lg">
                  <div className="flex  ">
                    {program.attributes.Slide &&
                      program.attributes.Slide[0] && (
                        <div key={index}>
                          <img
                            className=" my-2 object-contain  sm:w-[900px] w-[300px]  sm:h-[200px]"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              program.attributes.Slide[0].Image.data.attributes
                                .url
                            }
                            alt="Service"
                          />
                        </div>
                      )}
                    <div className="px-2 sm:pl-5 sm:h-20 p-[10px] sm:p-0 sm:mt-7  ">
                      <h3 className="sm:text-[20px] ml-[10px] font-bold text-[12px]  sm:text-left">
                        {program.attributes.Judul}
                      </h3>
                      <div className="sm:mt-2  ">
                        <div className="flex">
                          {program.attributes.Deskripsi &&
                            program.attributes.Deskripsi[0] && (
                              <p className="text-[8px] px-[10px] text-justify sm:text-[14px]">
                                {window.innerWidth >= 1200
                                  ? program.attributes.Deskripsi[0].Paragraf.slice(
                                      0,
                                      220
                                    )
                                  : program.attributes.Deskripsi[0].Paragraf.slice(
                                      0,
                                      100
                                    )}

                                {program.attributes.Deskripsi[0].Paragraf
                                  .length > 80 && (
                                  <span className="text-blue-500">
                                    .... Lihat Selengkapnya
                                  </span>
                                )}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;
