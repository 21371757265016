import React, { useState } from "react";
import { Link } from "react-router-dom";
const Tentang = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    console.log(`Selected option: ${option}`);
    setIsOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="relative  inline-block z-60">
      <button className="flex" onClick={handleButtonClick}>
        <p>Tentang</p>
      </button>
      {isOpen && (
        <div className="ml-[-40px] ">
          <div className="  absolute z-20 text-black text-[18px] mt-2 py-2 w-40 text-center bg-white pl-[-5px] hover:shadow-sm rounded shadow-lg">
            <ul>
              <li
                className="cursor-pointer hover:text-white px-4 py-2 hover:bg-[#8C6946]"
                onClick={() => handleOptionClick("profil-sekolah")}
              >
                <Link to={`profil-sekolah`}>Profil Sekolah</Link>
              </li>
              <li
                className="cursor-pointer hover:text-white px-4 py-2 hover:bg-[#8C6946]"
                onClick={() => handleOptionClick("sejarah-pondok")}
              >
                <Link to={`sejarah-pondok`}>Sejarah Pondok</Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tentang;
