import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { handlerApi } from "../service/handlerApi";
import { Asrama } from "../Interface/Asrama.inteface";

// ... (previous imports)

const NamaAsrama = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Asrama[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchResults, setSearchResults] = useState<Asrama[]>([]);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const itemsPerPage = 4;
  const maxVisiblePages = 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await handlerApi("/api/nama-asramas?populate=*");
        setData(result);
      } catch (error) {
        // Handle error here
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleSearchCriteriaChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchCriteria(e.target.value);
  };

  const handleSearchButtonClick = async () => {
    setSearchButtonClicked(true);
    const searchData = searchCriteria.toLowerCase();

    try {
      // Fetch all data from all pages
      const allData = await Promise.all(
        Array.from({ length: totalPages }, (_, index) =>
          handlerApi(`/api/nama-asramas?page=${index + 1}&populate=*`)
        )
      );

      // Flatten the array of arrays into a single array
      const flattenedData = allData.flatMap((page) => page);

      // Perform search across all data based on specific columns
      const results = flattenedData.filter((asrama) => {
        // Adjust the column names based on your data structure
        const judul = asrama.attributes.Judul.toLowerCase();
        const kategori = asrama.attributes.Kategori.toLowerCase();
        // Add more columns as needed

        // Perform search based on the specified columns
        return judul.includes(searchData) || kategori.includes(searchData);
        // Add more conditions for additional columns
      });

      setSearchResults(results);
    } catch (error) {
      // Handle error here
      console.error("Error fetching data for search:", error);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data?.length || 0);

  const totalPages = data ? Math.ceil(data.length / itemsPerPage) : 0;

  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  const currentData = searchButtonClicked ? searchResults : data || [];

  return (
    <div className="w-full bg-white py-32 font-andika lg:py-50">
      <div className="bg-[#8C6946] text-white w-full px-10 sm:px-20 py-3 sm:mt-[-50px] mt-[-65px]">
        <p className="sm:text-[16px] text-[13px]">Sejarah Pondok &gt; Asrama</p>
      </div>
      <div className="sm:text-[36px] text-[25px] text-center mt-5 font-bold">
        <h3>ASRAMA </h3>
      </div>
      <div>
        <div className="w-[200px] mt-[10px] px-[40px]">
          <div className="sm:absolute sm:right-[90px] sm:mt-[-80px] ">
            <input
              type="text"
              placeholder="Ketik Pencarian"
              value={searchCriteria}
              onChange={handleSearchCriteriaChange}
              className="sm:p-2 p-1 text-[12px] sm:font-bold border-2 border-black rounded-md"
            />
            <button
              onClick={handleSearchButtonClick}
              className="px-3 py-2 ml-2 bg-gray-300 rounded-md"
            >
              Cari
            </button>
          </div>
        </div>
        <div className="my-5 sm:mt-[60px] px-10 sm:px-20 grid grid-cols-1 sm:grid-cols-2 gap-5">
          {isLoading ? (
            <div className="pulse-animation"></div>
          ) : currentData.length > 0 ? (
            currentData.slice(startIndex, endIndex).map((asrama, index) => (
              <div key={index}>
                <Link to={`${asrama.attributes.Slug}`}>
                  <div className="bg-white hover:bg-slate-200 mb-5 shadow-md flex flex-col sm:flex-row sm:w-7xl p-5 px-5 rounded-lg">
                    <div key={index} className="sm:w-[500px]">
                      <img
                        className=""
                        src={
                          process.env.REACT_APP_BASE_URL +
                          asrama.attributes.Image.data.attributes.url
                        }
                        alt="Service"
                      />
                    </div>
                    <div className="w-2xl px-2 sm:pl-5  mt-5">
                      <h3 className="sm:text-[24px] text-bold text-[24px] text-center sm:text-left">
                        {asrama.attributes.Judul}
                      </h3>
                      <p className="text-center sm:text-left">
                        {asrama.attributes.Kategori}
                      </p>
                      <div className="sm:mt-3 mt-2 ">
                        {asrama.attributes.Deskripsi &&
                          asrama.attributes.Deskripsi[0] && (
                            <div className="text-[14px] sm:text-left text-center">
                              {window.innerWidth >= 1200
                                ? asrama.attributes.Deskripsi[0].Paragraf.slice(
                                    0,
                                    100
                                  ) + "..."
                                : asrama.attributes.Deskripsi[0].Paragraf.slice(
                                    0,
                                    100
                                  ) + "..."}
                              {asrama.attributes.Deskripsi[0].Paragraf.length >
                                80 && (
                                <span className="text-blue-400 hover:text-blue-800">
                                  Lihat selengkapnya
                                </span>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="text-center">Asrama Tidak Di Temukan</div>
          )}
        </div>
        <div className="flex sm:absolute sm:right-[70px] mt-5">
          {totalPages > 1 && (
            <div className="flex">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className={`mx-2 p-2 rounded-md ${
                  currentPage === 1 ? "bg-gray-100" : "bg-gray-200"
                }`}
                disabled={currentPage === 1}
              >
                Sebelumnya
              </button>
              {Array.from({ length: endPage - startPage + 1 }).map(
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(startPage + index)}
                    className={`mx-2 p-2 rounded-full ${
                      currentPage === startPage + index
                        ? "bg-gray-300"
                        : "bg-gray-100"
                    }`}
                  >
                    {startPage + index}
                  </button>
                )
              )}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className={`mx-2 p-2 rounded-md ${
                  currentPage === totalPages ? "bg-gray-100" : "bg-gray-200"
                }`}
                disabled={currentPage === totalPages}
              >
                Selanjutnya
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NamaAsrama;
