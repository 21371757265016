import { Link } from "react-router-dom";
import { useFetchData } from "../hooks/fetchData";
import { handlerApi } from "../service/handlerApi";
import { Beranda } from "../Interface/Beranda.interface";

const ArtikelHome = () => {
  const { data } = useFetchData<Beranda>(() =>
    handlerApi(
      "/api/beranda?populate[0]=artikels&populate[1]=artikels.Image&populate[2]=artikels.Deskripsi_Artikel&populate[3]=Layanan.Image&populate[4]=paqs"
    )
  );

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-full bg-white py-2 font-andika lg:py-50">
      <div className="sm:text-[36px] text-[20px] pl-7 mt-10 ">
        <h3 className="text-center font-bold">Artikel</h3>
        <p className="text-[15px] sm:text-[20px] sm:pt-3 ml-[210px] hover:text-blue-500 sm:ml-[1050px] ">
          <Link to={"artikel"} onClick={scrollToTop}>
            Lihat Berikutnya{" "}
          </Link>
        </p>
      </div>
      <div>
        <div className="my-5 px-10 sm:px-20 grid grid-cols-1 sm:grid-cols-2 gap-5">
          {data?.attributes.artikels.data.map((artikel, index) => (
            <div key={index}>
              <Link to={`${artikel.attributes.Slug}`} onClick={scrollToTop}>
                <div className="bg-white hover:bg-slate-200 sm:px-[20px] shadow-md flex flex-col sm:flex-row sm:w-7xl px-5 rounded-sm">
                  <div className="flex">
                    <img
                      className=" sm:w-[200px] sm:rounded-md sm:h-[200px] object-contain h-[70px] w-[100px]"
                      src={
                        process.env.REACT_APP_BASE_URL +
                        artikel.attributes.Image.data.attributes.url
                      }
                      alt="Service"
                    />
                    <div className="px-2 sm:pl-5 h-20 mt-2">
                      <div className="sm:mt-6 mt-2">
                        <div className="flex">
                          <p className="text-[7px] sm:text-[12px] text-[#939393]">
                            {artikel.attributes.UpdateArtikel}
                          </p>
                        </div>
                      </div>
                      <h3 className="sm:text-[15px] font-bold text-[12px] sm:text-left">
                        {artikel.attributes.Judul}
                      </h3>
                      <div className="sm:mt-2 hidden sm:block">
                        <div className="flex ">
                          {artikel.attributes.Deskripsi_Artikel[0] && (
                            <div
                              key={0}
                              className="text-[10px] text-justify  sm:text-[12px]"
                            >
                              {artikel.attributes.Deskripsi_Artikel[0].Paragraf
                                .length > 100
                                ? artikel.attributes.Deskripsi_Artikel[0].Paragraf.substring(
                                    0,
                                    100
                                  ) + "..."
                                : artikel.attributes.Deskripsi_Artikel[0]
                                    .Paragraf}
                              <span className="text-blue-500">
                                Lihat Selengkapnya
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
          ;
        </div>
      </div>
    </div>
  );
};

export default ArtikelHome;
