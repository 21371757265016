import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFetchData } from "../components/hooks/fetchData";
import { Artikel } from "../components/Interface/Artikel.interface";
import { handlerApi } from "../components/service/handlerApi";

const ArtikelPage = () => {
  const { data } = useFetchData<Artikel[]>(() =>
    handlerApi("/api/artikels?populate=Image&populate=Deskripsi_Artikel")
  );

  // Define the number of items per page
  const itemsPerPage = 3; // Display 1 item per page

  // State to track the current page
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Scroll to the top when the page changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  if (!data) {
    return <div>Loading...</div>;
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Calculate the range of pages to display in pagination
  const pagesToShow = 3; // Total number of pagination buttons to display
  const halfPagesToShow = Math.floor(pagesToShow / 2);

  // Calculate startPage and endPage with the current page in the center
  let startPage = currentPage - halfPagesToShow;
  let endPage = currentPage + halfPagesToShow;

  // Ensure that startPage and endPage stay within valid bounds
  if (startPage < 1) {
    startPage = 1;
    endPage = Math.min(totalPages, pagesToShow);
  }
  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, totalPages - pagesToShow + 1);
  }

  // Get the data for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-full font-andika bg-white ">
      <p className="sm:text-[36px] text-2xl sm:px-20 px-7 sm:py-10 pt-[80px] sm:pt-[120px] ">
        Artikel
      </p>
      {currentPageData.map((artikel, index) => (
        <div key={index}>
          <Link
            to={`${artikel.attributes.Slug}`}
            className="sm:flex sm:px-10 p-7 shadow-md sm:mx-[80px] px-[50px]  "
          >
            <div className="px-[40px] ">
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  artikel.attributes.Image.data.attributes.url
                }
                alt="artikel"
                className="rounded-md sm:w-[800px]  w-[300px] h-[250px] "
              />
            </div>
            <div className="sm:px-[50px] px-[40px] sm:mt-[20px]">
              <p className="text-gray-400 xl:text-[16px] text-xs">
                {artikel.attributes.UpdateArtikel}
              </p>
              <h3 className="sm:text-[32px] text-[20px] font-bold my-2 sm:my-5">
                {artikel.attributes.Judul}
              </h3>
              {artikel.attributes.Deskripsi_Artikel[0] && (
                <div
                  key={0}
                  className=" text-[15px] text-justify k sm:text-[16px]"
                >
                  {artikel.attributes.Deskripsi_Artikel[0].Paragraf &&
                    (artikel.attributes.Deskripsi_Artikel[0].Paragraf.length >
                    200
                      ? artikel.attributes.Deskripsi_Artikel[0].Paragraf.slice(
                          0,
                          200
                        ) + "..."
                      : artikel.attributes.Deskripsi_Artikel[0].Paragraf)}
                  <span className="text-blue-500">Lihat Selengkapnya</span>
                </div>
              )}
            </div>
          </Link>
        </div>
      ))}

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        {Array.from({ length: pagesToShow }, (_, i) => (
          <button
            key={i}
            onClick={() => handlePageChange(startPage + i)}
            className={`px-4 py-2 mx-2 ${
              currentPage === startPage + i
                ? "bg-blue-500 text-white rounded-full"
                : "bg-gray-200 rounded-full"
            }`}
          >
            {startPage + i}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ArtikelPage;
