import React, { useEffect, useState } from "react";

import ProgramLainnya from "./ProgramLainnya";
import { useParams } from "react-router-dom";

import { handlerApi } from "../../service/handlerApi";
import { ProfilSekolah } from "../../Interface/ProfilSekolah.interface";
import { Programs } from "../../Interface/Progams.interface";

const DetailProgram = () => {
  const { slug } = useParams<{ slug: string }>();
  const [data, setData] = useState<Programs[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handlerApi(
          `/api/programs?populate[0]=Slide.Image&populate=Deskripsi&filters[slug][$eq]=${slug}`
        );
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [slug]);

  const program = data?.find((program) => program.attributes.slug === slug);

  if (!program)
    return (
      <div className="h-screen justify-center flex items-center">
        Tidak ada Program yang perlu di Muat......
      </div>
    );

  const { attributes } = program; // Destructuring object article
  return (
    <div className="sm:flex">
      <div>
        <div className="  font-andika text-justify px-[20px] sm:pl-[80px] ">
          <div className="pb-5 ">
            <p className=" sm:text-[24px] text-[18px] font-bold pt-[80px] sm:pt-[100px]">
              {attributes.Judul}
            </p>
          </div>
          <div className="sm:flex sm:gap-4">
            {attributes.Slide.map((progm, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_BASE_URL}${
                  progm.Image.data.attributes.url || ""
                }`}
                alt="image"
                className="h-[200px] mb-[10px]"
              />
            ))}
          </div>
          {attributes.Deskripsi.map((paragraf, index) => (
            <div
              key={index}
              className="pt-3 text-[16px] sm:text-[20px] sm:mr-10"
            >
              <p className="indent-[20px] text-justify">{paragraf.Paragraf}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <ProgramLainnya />
      </div>
    </div>
  );
};

export default DetailProgram;
