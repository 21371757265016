import Sliders from "./Sliders";
import { useFetchData } from "../hooks/fetchData";
import { handlerApi } from "../service/handlerApi";
import { Informasi } from "../Interface/Informasi.interface";

const Hero = () => {
  const { data } = useFetchData<Informasi>(() =>
    handlerApi(
      "/api/informasi?populate[0]=Informasi&populate[1]=Slide.Image&populate[2]=Persyaratan_Umur&populate[3]=Persyaratan_Administrasi"
    )
  );

  // Menambahkan penanganan jika data tidak tersedia
  if (!data) {
    return (
      <div className="bg-indigo-500 ...">
        <svg
          className="animate-spin h-5 w-5 mr-3 ..."
          viewBox="0 0 24 24"
        ></svg>
        Processing...
      </div>
    );
  }

  return (
    <div className="max-w-full   ">
      <div className="">
        {data.attributes.Informasi.map((teks, index) => (
          <div
            key={index}
            className="bg-[#954141] fixed sm:mt-[80px] mt-[60px] z-20 w-full p-1 text-[12px] sm:text-[18px] text-white "
          >
            <p className="marquee text-full">{teks.Deskripsi}</p>
          </div>
        ))}
      </div>
      <div className="px-1">
        <Sliders />
      </div>
    </div>
  );
};

export default Hero;
