import Beranda from "./Beranda";
import Layanan from "./Layanan";
import Kontak from "./Kontak";
import Sosial from "./Sosial";
import { Logomina } from "../../assets/img/tarwil";
const Footer = () => {
  return (
    <footer>
      <div className="w-full mt-10 mx-auto font-andika p-10 sm:px-20 bg-slate-100">
        <div className="flex sm:flex-row flex-col">
          <div className="mb-3 flex sm:block gap-5 sm:gap-0 ">
            <img
              src={Logomina}
              alt="logoaruna"
              className="w-[130px] h-[76px] object-contain mt-3 sm:mt-0 "
            />
            <div className="sm:w-[423px] text-16 font-w-400 ">
              <h4>Alamat :</h4>
              <p className="text-[14px] sm:text-[16px]">
                Jl. Irigasi Dsn. Krajan I Rt. 07/04 Sukamerta Rawamerta-Karawang
              </p>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col  mt-10">
            <div className="sm:px-8">
              <Beranda />
            </div>

            <div className="sm:px-8 mt-5 sm:mt-0">
              <Layanan />
            </div>
            <div className="sm:px-8 sm:mr-10 mt-5 sm:mt-0">
              <Kontak />
            </div>

            <div className="mt-5 sm:mt-0">
              <Sosial />
            </div>
          </div>
        </div>
      </div>

      <div className=" text-center sm:px-2 px-5 py-4">
        <p>Copyright ©2023 MIS Tarbiyatul Wildan. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
