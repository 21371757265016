import React, { useState } from "react";
import axios from "axios";
import { ChatFrom } from "../Interface/ChatFrom.interface";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setSubmitting(true);

      const formData: ChatFrom = {
        data: {
          Nama_lengkap: name,
          Email: email,
          No_Telepon: number,
          Pesan: message,
        },
      };

      const response = await axios.post(
        "http://localhost:1337/api/from-chats",
        formData
      );

      if (response.status === 200) {
        setSuccess(true);
        setName("");
        setEmail("");
        setNumber("");
        setMessage("");
        setError("");
      } else {
        setError("Failed to send data to the server");
      }
    } catch (err) {
      setError("Something went wrong. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="sm:flex mt-[200px] sm:mt-0 px-5 sm:px-10 font-andika">
      <div className="mt-500 pl-10 sm:w-[500px]">
        <p className="sm:text-[32px] text-lg font-bold ">
          Anda memiliki pertanyaan ?
        </p>
        <p className="sm:text-[20px] text-[#828282] mt-3">
          kirim pesan, dan kami akan menjawab pertanyaan anda
        </p>
      </div>
      <div className="bg-white p-5 sm:p-20 flex justify-center items-center h-7xl">
        <div className="bg-white rounded-xl p-8 shadow-xl w-[500px]">
          <h2 className="text-2xl text-center font-bold mb-6">Hubungi Kami</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="fullname" // Match this with the "id" of the associated input
              >
                Nama Lengkap
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="fullname" // Match this with the "for" of the associated label
                type="text"
                placeholder="Masukkan Nama Lengkap"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="fromemail"
              >
                Email
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="fromemail"
                type="email"
                placeholder="Masukkan Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                No. Handphone
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="number"
                placeholder="Masukkan No. Handphone"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="message"
              >
                Pesan
              </label>
              <textarea
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                placeholder="Masukkan Pesan Anda"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="text-center bg-[#3BB3C3] hover:bg-slate-500 rounded-xl">
              <button type="submit" className="p-3 font-bold">
                {submitting ? "Sedang mengirim..." : "Kirim Pesan"}
              </button>
            </div>
            {success && (
              <div className="text-green-600 text-center mt-4">
                Pesan telah terkirim dengan sukses!
              </div>
            )}
            {error && (
              <div className="text-red-600 text-center mt-4">{error}</div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
