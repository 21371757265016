import { faChevronDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Vector } from "../../assets/img/icon";
import { useFetchData } from "../hooks/fetchData";
import { handlerApi } from "../service/handlerApi";
import { Beranda } from "../Interface/Beranda.interface";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(-1);

  // Fetch data using useFetchData
  const { data } = useFetchData<Beranda>(() =>
    handlerApi(
      "/api/beranda?populate[0]=artikels&populate[1]=artikels.Image&populate[2]=artikels.Deskripsi_Artikel&populate[3]=Layanan.Image&populate[4]=paqs"
    )
  );

  if (!data) {
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
      <p>Loading.....</p>
    </div>;
  }

  const toggle = (index: number) => {
    if (openIndex === index) {
      // Jika yang diklik adalah yang sedang terbuka, tutup.
      setOpenIndex(-1);
    } else {
      // Jika yang diklik adalah yang tertutup, buka.
      setOpenIndex(index);
    }
  };

  return (
    <div className="sm:mt-[100px] ">
      <div className="text-center">
        <p className="sm:text-[25px] sm:font-bold">
          Pertanyaan Yang Sering Di Tanyakan
        </p>
      </div>
      {data?.attributes.paqs.map((slide, index) => (
        <div
          key={index}
          className="mt-4 sm:mx-[100px] mx-[25px]  shadow-md sm:rounded-lg rounded-md"
        >
          <div
            onClick={() => toggle(index)}
            className="cursor-pointer shadow-md sm:p-[10px] p-[5px] flex sm:text-[20px] text-[12px]"
          >
            <img src={Vector} alt="Vector" className="object-contain" />
            <p className="sm:px-[40px] px-[20px]">{slide.Pertanyaan}</p>
            <FontAwesomeIcon
              icon={openIndex === index ? faChevronDown : faAngleRight}
              className="sm:text-[25px] absolute sm:right-[130px] right-[40px] "
            />
          </div>
          {openIndex === index && (
            <div className="sm:p-[10px] p-[5px]">
              <p>{slide.Jawaban}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Faq;
