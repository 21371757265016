import React, { useState } from "react";

import { Link } from "react-router-dom";
import Tentang from "./Tentang";

import { Logomina } from "../../assets/img/tarwil";

const Nav = () => {
  const [open, setOpen] = useState(false);

  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="shadow-md w-full text-[16px] text-[#3BB3C3] mt-[-20px]  left-0 fixed z-40 ">
      <div className="md:flex items-center justify-between bg-white py-4 sm:px-[50px] px-0 ">
        <div className="flex items-center">
          <div>
            <Link to={"/"}>
              <div className="flex">
                <img
                  src={Logomina}
                  alt="logoaruna"
                  className="sm:w-[123px]  sm:h-[70px] w-[100px] h-[50px] pt-3 object-contain"
                />
                <div className="w-[150px] sm:w-[300px] absolute ml-[75px] sm:ml-[100px] mt-4">
                  <p className="text-[12px] sm:text-[20px] sm:leading-[20px] sm:mt-[6px] font-Staatliches">
                    MADRASAH IBTIDAIYAH NIHAYATUL AMAL
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
          >
            <i
              className={
                open ? "fa-solid fa-xmark" : "fa-sharp fa-solid fa-bars"
              }
            ></i>
          </div>
        </div>
        <div className="hover:text-[25px] hover:text-bold">
          <ul
            className={`md:flex md:items-center  bg-white md:pb-0 pb-3 absolute md:static  md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all  duration-500 ease-in ${
              open ? "top-20 " : "top-[-490px]"
            }`}
          >
            <li
              className="sm:ml-8 rounded-lg p-2  text-[20px] mt-5 sm:mt-0 "
              onClick={handleNavClick}
            >
              <Link to={`/`}>Home</Link>
            </li>

            <li className="md:ml-8 text-[20px]  rounded-lg p-2  ">
              <Tentang />
            </li>

            <li
              className="md:ml-8 text-[20px]  rounded-lg p-2 "
              onClick={handleNavClick}
            >
              <Link to={`information`}>Informasi</Link>
            </li>
            <li
              className="md:ml-8 text-[20px]  rounded-lg p-2 "
              onClick={handleNavClick}
            >
              <Link to={`kontak`}>Kontak Kami</Link>
            </li>
            <li
              className="md:ml-8 text-[20px]  rounded-lg p-2 "
              onClick={handleNavClick}
            >
              <Link to={`artikel`}>Artikel</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav;
