import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFetchData } from "../components/hooks/fetchData";
import { handlerApi } from "../components/service/handlerApi";
import { Programs } from "../components/Interface/Progams.interface";

const ProgramPage = () => {
  const itemsPerPage = 3; // Display 2 items per page
  const { data } = useFetchData<Programs[]>(() =>
    handlerApi("/api/programs?populate[0]=Slide.Image&populate=Deskripsi")
  );
  const [currentPage, setCurrentPage] = useState(1);

  if (!data) {
    return <div>Loading...</div>;
  }

  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Calculate the range of pages to display in pagination
  const pagesToShow = 3; // Total number of pagination buttons to display
  const halfPagesToShow = Math.floor(pagesToShow / 2);

  // Calculate startPage and endPage with the current page in the center
  let startPage = currentPage - halfPagesToShow;
  let endPage = currentPage + halfPagesToShow;

  // Ensure that startPage and endPage stay within valid bounds
  if (startPage < 1) {
    startPage = 1;
    endPage = Math.min(totalPages, pagesToShow);
  }
  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, totalPages - pagesToShow + 1);
  }

  // Get the data for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="w-full font-andika bg-white mt-[10px] ">
      <div className="bg-[#A07A53] pt-[55px] sm:pt-[80px] w-full">
        <p className="text-[16px] sm:px-20 px-7 text-white py-2">
          Info &gt; Program
        </p>
      </div>
      <p className="sm:text-[36px] text-2xl sm:px-20 px-7 sm:py-10  ">
        Program Sekolah
      </p>
      {currentPageData.map((program, index) => (
        <div key={index}>
          <Link
            to={`${program.attributes.slug}`}
            className="sm:px-20 p-7 shadow-md sm:flex"
            onClick={handleNavClick}
          >
            {program.attributes.Slide.length > 0 && (
              <img
                key={index}
                src={
                  process.env.REACT_APP_BASE_URL +
                  program.attributes.Slide[0].Image.data.attributes.url
                }
                alt="artikel"
                className="rounded-xl object-contain sm:w-[400px] sm:h-[315px] w-[300px] h-[200px]"
              />
            )}
            <div className="sm:mx-10 sm:pt-5 mt-2">
              <h3 className="sm:text-[32px] text-[18px] font-bold my-2 sm:my-5">
                {program.attributes.Judul}
              </h3>
              {program.attributes.Deskripsi[0] && (
                <div
                  key={0}
                  className="text-[13px] px-[5px] pr-[25px] sm:pr-0  text-justify  sm:text-[20px]"
                >
                  {program.attributes.Deskripsi[0].Paragraf.length > 200
                    ? program.attributes.Deskripsi[0].Paragraf.substring(
                        0,
                        200
                      ) + "..."
                    : program.attributes.Deskripsi[0].Paragraf}
                  <span className="text-blue-400">Baca selengkapnya</span>
                </div>
              )}
            </div>
          </Link>
        </div>
      ))}
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        {Array.from({ length: pagesToShow }, (_, i) => (
          <button
            key={i}
            onClick={() => handlePageChange(startPage + i)}
            className={`px-4 py-2 mx-2 ${
              currentPage === startPage + i
                ? "bg-blue-500 text-white rounded-full"
                : "bg-gray-200 rounded-full"
            }`}
          >
            {startPage + i}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ProgramPage;
