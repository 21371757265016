export const Banner = require("../tarwil/banner.jpeg");
export const Image = require("../tarwil/IMAGE.jpg");
export const Image1 = require("../tarwil/image1.jpg");
export const Image2 = require("../tarwil/image2.jpg");
export const Image3 = require("../tarwil/image3.jpg");
export const Image4 = require("../tarwil/image4.jpg");
export const Img = require("../tarwil/img.jpg");
export const Img1 = require("../tarwil/img1.jpg");
export const Img2 = require("../tarwil/img2.jpg");
export const Img3 = require("../tarwil/img3.jpg");
export const Img4 = require("../tarwil/img4.jpg");
export const Logokemenag = require("../tarwil/Logo-Kemenag.png");
export const Kemendikbud = require("../tarwil/kemendikbud.png");
export const Logomina = require("../tarwil/logomina.png");
export const Logotarwil = require("../tarwil/logotarwil.jpg");
export const Mage = require("../tarwil/mage.jpg");
export const Mage2 = require("../tarwil/mage2.jpg");
export const Mage1 = require("../tarwil/mage1.jpg");
export const Saya1 = require("../tarwil/saya1.jpg");
export const bg = require("../tarwil/bg1.png");
export const imgpondok = require("../tarwil/imgpondok.jpeg");
export const Imgformulir = require("../tarwil/imgformulir.jpg");
