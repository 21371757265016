import React from "react";
import { Link } from "react-router-dom";
import { useFetchData } from "../../hooks/fetchData";
import { ProfilSekolah } from "../../Interface/ProfilSekolah.interface";
import { handlerApi } from "../../service/handlerApi";
import { Programs } from "../../Interface/Progams.interface";

const ProgramLainnya = () => {
  const { data } = useFetchData<Programs[]>(() =>
    handlerApi("/api/programs?populate[0]=Slide.Image&populate=Deskripsi")
  );
  if (!data) {
    return <div>Loading...</div>;
  }

  const maxArticlesToShow = 3;
  const articlesToShow = data?.slice(0, maxArticlesToShow);
  return (
    <div className="sm:mr-10 mt-[120px] px-[30px]">
      <h3 className=" sm:text-[30px] text-[18px] mt-5 sm:mt-0 font-bold">
        Program Lainnya
      </h3>

      {articlesToShow.map((data, index) => (
        <React.Fragment key={index}>
          <Link to={`/program/${data.attributes.slug}`}>
            <p className="w-[300px] sm:text-[24px] text-[18px] my-2 sm:my-5 sm:leading-[20px]">
              <button className="shadow-md rounded-lg hover:bg-slate-200 p-4">
                <div className="flex sm:block">
                  {data.attributes.Slide.length > 0 && (
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        data.attributes.Slide[0].Image.data.attributes.url
                      }
                      alt="image"
                      className="sm:h-[200px] h-[100px] object-contain  rounded-lg"
                    />
                  )}
                  <div>
                    <p className="sm:text-[15px] text-left sm:text-center font-bold sm:mt-4 ml-2 sm:ml-0 text-[12px]">
                      {data.attributes.Judul}
                    </p>
                    {data.attributes.Deskripsi[0] && (
                      <p
                        key={0}
                        className="text-[8px] px-[10px] text-justify sm:text-[14px]"
                      >
                        {data.attributes.Deskripsi[0].Paragraf.length > 60
                          ? `${data.attributes.Deskripsi[0].Paragraf.slice(
                              0,
                              60
                            )}...`
                          : data.attributes.Deskripsi[0].Paragraf}
                        {data.attributes.Deskripsi[0].Paragraf.length > 80 && (
                          <span className="text-blue-500">
                            Lihat Selengkapnya
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </button>
            </p>
          </Link>
          <hr />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgramLainnya;
