import Hero from "../components/beranda/Hero";
import Info from "../components/beranda/Info";
import Layanan from "../components/beranda/Layanan";
import Faq from "../components/beranda/Faq";
import ArtikelHome from "../components/beranda/ArtikelHome";

const Home = () => {
  return (
    <div>
      <Hero />
      <Info />
      <Layanan />

      <ArtikelHome />

      <Faq />
    </div>
  );
};

export default Home;
