import Program from "../components/Sekolah/Program/program";
import Kurikulum from "../components/Sekolah/kurikulum";
import { handlerApi } from "../components/service/handlerApi";
import { useFetchData } from "../components/hooks/fetchData";
import { ProfilSekolah } from "../components/Interface/ProfilSekolah.interface";
import { Plane } from "../assets/img/icon";

const Sekolah = () => {
  const { data } = useFetchData<ProfilSekolah>(() =>
    handlerApi(
      "/api/profil-sekolah?populate[0]=Dashboard_Sekolah.Deskripsi&populate[1]=programs.Slide.Image&populate[2]=Dashboard_Sekolah.Image&populate[3]=Misi_Sekolah&populate[4]=PageKurikulum.Deskripsi&populate[5]=PageKurikulum.kurikulums.Deskripsi"
    )
  );

  // Menambahkan penanganan jika data tidak tersedia
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="w-full  
    bg-white  py-[100px] sm:py-[100px] font-andika"
    >
      <div className="bg-[#A07A53] sm:mt-[-20px] mt-[-40px] w-full">
        <p className="text-[14px] sm:text-[20px] sm:px-20 px-7 text-white py-3">
          Beranda &gt; Profil Sekolah
        </p>
      </div>

      <div className="p-7 sm:p-20">
        <h3 className="text-[14px] sm:text-[36px]  font-bold mb-2">
          {data.attributes.Dashboard_Sekolah.Judul ||
            "Tidak Ada Judul yang Tersedia"}
        </h3>
        <div className="flex flex-col-reverse sm:flex-row mt-5 sm:w-full sm:mt-10">
          <div>
            {data.attributes.Dashboard_Sekolah.Deskripsi.map((teks, index) => (
              <p
                key={index}
                className="sm:text-[20px]   sm:indent-10 text-[17px] mt-5   leading-[24px] sm:leading-[35px] text-justify sm:w-[634px]"
              >
                {teks.Paragraf}
              </p>
            ))}
          </div>
          <div className="pl-10 w-full text-center">
            <img
              className=" h-[150px] sm:w-[520.195px] object-contain w-[250px] sm:h-[366px]"
              src={
                process.env.REACT_APP_BASE_URL +
                (data.attributes.Dashboard_Sekolah.Image.data.attributes.url ||
                  " ")
              }
              alt="Profile"
            />
          </div>
        </div>
      </div>

      {/* VISI */}
      <div className="sm:mt-5 font-andika sm:pl-20 p-10">
        <div className="flex">
          <img
            src={Plane}
            alt="Plane"
            className="sm:w-[40.026px] w-[30px] h-[30px]  sm:h-[40.026px]"
          />
          <p className="pl-2 text-[20px] sm:text-[24px] font-bold">VISI</p>
        </div>
        <ul className="list-disc sm:ml-[70px] sm:w-[900px] font-w-400 sm:leading-[40px] leading-[25px]  sm:w-100 text-[17px] text-justify sm:text-[20px] ">
          <p>" {data.attributes.Visi_Sekolah} "</p>
        </ul>
      </div>

      <div className=" sm:pl-20 pl-8">
        <div className="flex">
          <img
            src={Plane}
            alt="Plane"
            className="sm:w-[40.026px] sm:h-[40.026px] w-[30px] h-[30px]"
          />
          <p className="pl-2 text-[20px] sm:text-[24px] font-bold">MISI</p>
        </div>
        <ul className="list-disc mx-9 font-w-400 sm:leading-[40px] leading-[24px] sm:w-100 text-[17px] text-justify sm:text-left sm:text-[20px] ">
          {data.attributes.Misi_Sekolah.map((statement, index) => (
            <li key={index}>{statement.Paragraf}</li>
          ))}
        </ul>
      </div>
      <Kurikulum />

      <Program />
    </div>
  );
};

export default Sekolah;
